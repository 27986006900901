import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { TranslocoService } from '@jsverse/transloco';
import { ImageInfo } from '../common/interfaces/image-info.interface';


export interface Menu {
    id: number;
    display_name: string;
    public_id: string;
    user_id: number;
    images: ImageInfo[];
    view_type: 'list' | 'grid';
}

export enum ProductStatus {
    INACTIVE = 0,
    ACTIVE = 1
}

export interface Product {
    id: number;
    display_name: string;
    description: string;
    price: string;
    active: ProductStatus;
    images: ImageInfo[];
}

export interface PublicMenuWithProducts {
    displayName: string;
    publicId: string;
    currency: string;
    products: Product[];
    images: ImageInfo[];
    view_type: 'list' | 'grid';
}

@Injectable({
    providedIn: 'root'
})
export class CartaService {
    private apiUrl = `${environment.apiUrl}/menus`;

    constructor(private http: HttpClient, private translocoService: TranslocoService) { }

    // CRUD operations for menus

    createMenu(displayName: string, publicId?: string, viewType: 'list' | 'grid' = 'list'): Observable<Menu> {
        return this.http.post<Menu>(this.apiUrl, { displayName, publicId, viewType }).pipe(
            catchError(this.handleError)
        );
    }

    getMenus(): Observable<Menu[]> {
        return this.http.get<Menu[]>(this.apiUrl).pipe(
            catchError(this.handleError)
        );
    }

    getMenu(id: number): Observable<Menu> {
        return this.http.get<Menu>(`${this.apiUrl}/${id}`).pipe(
            catchError(this.handleError)
        );
    }

    updateMenu(id: number, displayName: string, publicId?: string, viewType?: 'list' | 'grid'): Observable<Menu> {
        return this.http.put<Menu>(`${this.apiUrl}/${id}`, { displayName, publicId, viewType }).pipe(
            catchError(this.handleError)
        );
    }

    deleteMenu(id: number): Observable<{ message: string }> {
        return this.http.delete<{ message: string }>(`${this.apiUrl}/${id}`).pipe(
            catchError(this.handleError)
        );
    }

    // Menu-Product relationship operations

    addProductToMenu(menuId: number, productId: number): Observable<{ message: string }> {
        return this.http.post<{ message: string }>(`${this.apiUrl}/${menuId}/products/${productId}`, {}).pipe(
            catchError(this.handleError)
        );
    }

    getProductsInMenu(menuId: number): Observable<Product[]> {
        return this.http.get<Product[]>(`${this.apiUrl}/${menuId}/products`).pipe(
            catchError(this.handleError)
        );
    }

    removeProductFromMenu(menuId: number, productId: number): Observable<{ message: string }> {
        return this.http.delete<{ message: string }>(`${this.apiUrl}/${menuId}/products/${productId}`).pipe(
            catchError(this.handleError)
        );
    }

    // Public access to menu products

    getPublicProductsInMenu(publicId: string): Observable<PublicMenuWithProducts> {
        return this.http.get<PublicMenuWithProducts>(`${this.apiUrl}/${publicId}/public-products`).pipe(
            catchError(this.handleError)
        );
    }

    // Image operations
    uploadMenuImage(menuId: number, imageFile: File, imageName: string, imageRole: string): Observable<Menu> {
        const formData = new FormData();
        formData.append('imageFile', imageFile, imageName);
        formData.append('imageName', imageName);
        formData.append('role', imageRole);

        return this.http.post<Menu>(`${this.apiUrl}/${menuId}/images`, formData).pipe(
            catchError(this.handleError)
        );
    }

    deleteMenuImage(menuId: number, imageFileName: string): Observable<{ message: string }> {
        return this.http.delete<{ message: string }>(`${this.apiUrl}/${menuId}/images/${imageFileName}`).pipe(
            catchError(this.handleError)
        );
    }

    updateProductInMenu(menuId: number, productId: number, productData: Partial<Product>): Observable<Product> {
        return this.http.put<Product>(`${this.apiUrl}/${menuId}/products/${productId}`, productData).pipe(
            catchError(this.handleError)
        );
    }

    private handleError(error: any) {
        console.error('An error occurred:', error);
        return throwError(() => new Error(this.translocoService.translate('carta.service.handleErrorMessage')));
    }
}